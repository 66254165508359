import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Collapsible from 'react-collapsible';
import ChevronDown from "../components/svg/chevronDown"
import Cta from "../components/cta"

const FaqsPage = () => (
  <Layout>
    <Seo 
        title="Frequently asked questions" 
        description="FAQs about Enabli's Performance Management software including answers on how it works and how it can help your company improve employee and company performance."
    />
        <section className="bg-blue-dk">
            <div className="container">
                <div className="row">
                    <div className="hero-subpage">
                        <div className="hero-subpage-text">
                            <h1>FAQs</h1>
                            <p className="lead">Find out more about the Enabli performance review platform below. If you have any other questions please <Link to="/contact">contact us</Link>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="last-section with-pad-top">
            <div className="container">
			    <div className="row">
                    <Collapsible 
                        transitionTime={100}
                        trigger={
                            <>
                            What is Enabli? <ChevronDown />
                            </>
                        }
                    >
                        <p>Enabli is a management software built for Go-To-Market teams. The platform produces ROI through reducing time in upskilling senior leadership, frontline management & employees and by helping make quicker/better decisions around staff retention using performance data. </p>
                    </Collapsible>
                    <Collapsible
                        transitionTime={100}
                        trigger={
                            <>
                            What does it cost? <ChevronDown />
                            </>
                        }
                    >
                        <p>View our <Link to="/#pricing">price table</Link> or <Link to="/contact">contact us</Link> to discuss your requirements.</p>
                    </Collapsible>
                    <Collapsible
                        transitionTime={100}
                        trigger={
                            <>
                            How do I set up Enabli? <ChevronDown />
                            </>
                        }
                    >
                        <p>Enabli is cloud-based software that runs in the browser. If you would like to discuss whether Enabli is a good fit for your company, or you would like to create an account, please contact <Link to="/contact">our sales team</Link>.</p>
                    </Collapsible>
                    <Collapsible
                        transitionTime={100}
                        trigger={
                            <>
                            Have you got a partner / reseller model? <ChevronDown />
                            </>
                        }
                    >
                        <p>Yes, we partner with tech resellers, consulting firms and service providers. If you're interested in joining our partner network <Link to="/contact">contact us</Link>.</p>
                    </Collapsible>
                </div>        
            </div>
        </section>

        <Cta />

  </Layout>
)

export default FaqsPage